import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import quote from "../assets/svg/quote.svg";
import "swiper/css/bundle";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

function Testimonial() {
  const [swiperRef, setSwiperRef] = useState(null);

  let appendNumber = 4;
  let prependNumber = 1;
  return (
    <React.Fragment>
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={5}
        spaceBetween={30}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="testimonialSwiper"
        breakpoints={{
          1281: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
      >        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">S</div>
                  <h4>Shubham W</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
                Has best instructors to develop German language skills
                efficiently for beginners as well as professionals. Instructors
                helped me build my confidence during difficulties and kept me
                motivated until I reach my goals!They have flexible schedules,
                affordable courses and interactive sessions. Highly recommended.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">C</div>
                  <h4>Charul patil</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
                I have completed my A1 course. It has been a fun learning ride.
                The concepts were eased into us with the help of many
                interactive activities, games and worksheet. Maam has been
                really good with her knowledge and efforts.I would highly
                recommend you if u really wanna learn German.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">A</div>
                  <h4>Apurva Joshi</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
                Rutuja mam is great teacher who ensures that all her students
                learn the language. She covers all the factors of the language
                like reading, speaking, writing and listening. Actually german
                grammar is difficult but she breaks it down so easily and makes
                it comprehensible. I highly recommend that you can start your
                journey with Rutuja mam.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">A</div>
                  <h4>Abhijeet Gaikar</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
                With the quench for learning a foreign language, I started
                learning German Online at Hyflex Education. The teaching was
                really convincing & interactive. Ma'am is interactive,
                approachable & focuses on learning. If anyone wants to learn a
                foreign language, I highly recommend Hyflex Education.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">S</div>
                  <h4>Shrirang Pathak</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
                The instructor is extremely knowledgeable and teaches
                meticulously. Her focus is on not only on grammer but also on
                improving communication skills. She is a great teacher indeed. I
                would really recommend joining her class.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">S</div>
                  <h4>Sampada Puranik</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
                a month ago Learning French has always been a goal of mine, and
                you made it possible to get closer to that goal with your
                disciplined yet approachable teaching style, Anushka. Merci
                beaucoup!!!
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">v</div>
                  <h4>VINITA PATIL</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
                a month ago Best staff of academy specifically rutuja mam 💫💫💫
                she is very comfortable and cooperative to students. The way she
                teaches which is absolutely best way for students to easily
                understand.Thank you mam 😊
              </p>
            </div>
          </div>
        </SwiperSlide>

    
       

        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">R</div>
                  <h4>Ruchi kulkarni</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
              I started my German language journey with Rutuja mam and it was a wonderful experience for me.  I have completed A1 level to B1 level. Definitely a good choice for people who are planning to learn a new language. The teaching method is great,timings are so flexible, the batches sizes are small which ensures that personal attention is given to each student.Even the quality of online classes (during lockdown) were at par with the offline ones. Thank you so much mam. I really recommend  to start German with Rutuja mam
              </p>
            </div>
          </div>
        </SwiperSlide>

     

       

        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">S</div>
                  <h4>Siddhesh Bhadke</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
              It was great experience learning at Hyfleex education. After joining the class my only regret was Why I didn't find this class soon. All the staff is very supportive and friendly especially Rutuja maam. They try to teach you to the fullest and make you capable to set foot in any country. The staff was always eager to solve all of my doubts whether they be academic related or admission related. A highly recommended institution in nashik.
              </p>
            </div>
          </div>
        </SwiperSlide>

    


    
        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">S</div>
                  <h4>Soham Kharkar</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
              I am really grateful that I was your student. I never thought German can be so easy to learn. But your guidance and style of teaching made it look so simple. Your availability is a add on. That's the best part about you that you were available all anytime when I had a doubt. You are a teacher that anyone will yearn for. Online classes were so difficult. But you made it so simple. So I am glad that you were my teacher. Vielen Dank Lehrerin 😊
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">C</div>
                  <h4>Chinmay Gujar</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
              German learning made easy ! It was a very great experience with Rutuja ma’am learning a new language. Special thanks for revision classes during my exam.
              </p>
            </div>
          </div>
        </SwiperSlide>
    
        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">T</div>
                  <h4>Tanuja Pingale</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
              I really enjoyed doing A2 course with you. You made the learning really intresting and personal attention was given to each student which was very good. You are friendly and amzing teacher.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">T</div>
                  <h4>Sampada Puranik</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
              Learning French has always been a goal of mine, and you made it possible to get closer to that goal with your disciplined yet approachable teaching style, Anushka.
              Merci beaucoup!!!
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card">
            <div className="card-content">
              <div className="nameCont">
                <div className="left">
                  <div className="profileBox">P</div>
                  <h4>Pratiksha Tambat</h4>
                </div>
                <img src={quote} alt="quote icon" className="quote" />
              </div>
              <p>
                The best german class in Nashik with proper guidance and
                Ms.Rutuja takes in a lot of effort to teach. Each and every
                concept is explained by her in detail without any hesitation.
                And there is a lot of interaction with the other students which
                helps to boost confidence. Would highly recommend.
              </p>
            </div>
          </div>
        </SwiperSlide>
        
      </Swiper>
    </React.Fragment>
  );
}

export default Testimonial;